<template>
  <div>
    <el-form
      label-position="right"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="60px"
      style="text-align:left"
    >
      <el-col :span="12">
        <el-form-item label="APPID" label-width="110px" prop="wx_appid">
          <el-input v-model="ruleForm.wx_appid" style="width: 100%" placeholder="请输入APPID"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="秘钥" label-width="110px" prop="wx_secret ">
          <el-input v-model="ruleForm.wx_secret " placeholder="请输入微信秘钥"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="服务器认证文件名称" label-width="200px" prop="wx_serverfile">
          <el-input v-model="ruleForm.wx_serverfile" placeholder="请输入服务器认证文件名称"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="服务器认证文件内容" label-width="200px" prop="wx_serverfileContent">
          <el-input v-model="ruleForm.wx_serverfileContent" placeholder="请输入服务器认证文件内容"></el-input>
          <!-- <el-upload
            class="avatar-uploader"
            :action="action"
            :on-success="successFile"
            :before-upload="beforeAvatarUpload"
          >
            <a
              v-if="ruleForm.wx_serverfileContent"
              style="width:300px;height:150px"
              class="avatar"
            >{{ruleForm.wx_serverfileContent}}</a>
            <el-button style="margin-left:20px" type="primary" plain size="small">上传</el-button>
            <span
              slot="tip"
              class="el-upload__tip"
              style="    display: inline-block;color: chocolate;margin: 0 10px;"
            >*340px*150px</span>
          </el-upload>-->
        </el-form-item>
      </el-col>

      <el-button style="float: right;" type="primary" @click="onSubmit('ruleForm')">保存</el-button>
    </el-form>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
// import baseUrl from '../../config/config';
export const customerEdit = api()('enterprise.config.edit.json');
export default {
  props: {
    code: String,
    userInfo: Object
  },
  data () {
    return {
      ruleForm: {
        wx_appid: '',
        wx_secret: '',
        wx_serverfile: '',
        wx_serverfileContent: ''
      },
      rules: {
        wx_appid: [{ required: true, message: '请输入APPID', trigger: 'blur' }],
        wx_secret: [{ required: true, message: '请输入秘钥', trigger: 'blur' }],
        wx_serverfile: [{ required: true, message: '请输入服务器认证文件名称', trigger: 'blur' }],
        wx_serverfileContent: [{ required: true, message: '请输入服务器认证文件内容', trigger: 'blur' }],
      },
      fileList: [],
      action: '',
    }
  },
  mounted () {
    this.action = window.location.origin + '/enterprise.upload.json';
    Object.keys(this.ruleForm).forEach(it => {
      this.ruleForm[it] = this.userInfo[it]

    })
    console.log(this.userInfo);
  },
  methods: {
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          customerEdit({
            ...this.ruleForm,
            code: this.code
          }
          ).then(() => {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            // window.history.back(-1);
          });
        } else {
          return false;
        }
      });

    },
    beforeAvatarUpload (file) {
      console.log(file);
    },
    successFile (res, fileList) {
      console.log(fileList);
      this.ruleForm.wx_serverfileContent = res.data;
      console.log(this.ruleForm.wx_serverfileContent);
      this.filePath = res.data;
    },
  },
}
</script>
<style lang="scss">
</style>